import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Profilemenu from "./Profilemenu";
import { Link } from "react-router-dom";
import { getChatlist,followaction } from "../../slices/chatSlice";
import { API_BASE_URL } from "../../utils/config";
import { getProfile } from "../../slices/profileSlice";
import Swal from "sweetalert2";
const Chat = () => {
   const dispatch = useDispatch();
   useEffect(() => {
     dispatch(getChatlist());
     dispatch(getProfile()); // Dispatch your API call action here using slug
   }, [dispatch]);
   const profileInfo = useSelector((state) => state.profile.profileInfo);
    const chatlist = useSelector((state) => state.chat.chatlistInfo);
    const [result, setResult] = useState(null);

   const addDefaultSrc =(ev)=> {
      ev.target.src = '/img/profile-img.png'
  };

  const follow = async (e, action, following_id) => {
   console.log('Data to dispatch:', { action, following_id });
   try {
     
     const result = await dispatch(followaction({
       action: action,
       following_id: following_id,
     }));
     setResult(result);
     console.log('API Response:', result);
 
     // Check if the API call was successful
     if (result.payload && result.payload.status === 200) { 
          // Show success message
          Swal.fire(result.payload.message);
     } else {
       Swal.fire(result.payload.message);
      
     }
   } catch (error) {
     // Handle any errors
     console.log('Error adding item to wishlist:', error);
     alert('An error occurred while adding item to wishlist.');
   }
 };

 useEffect(() => {
   if (result && result.payload && result.payload.status === 200) {
    
      dispatch(getChatlist());
      dispatch(getProfile());
   }
 }, [result]);
  return (
    <main className="sa-main-wrap sa-inner-pages-wrap">
    <section className="sa-profile-wrap">
     <div className="container">
     <div className="row">
        
     <Profilemenu profileInfo={profileInfo} follow={follow}/>

        <div className="col-lg-9">
           <div className="sa-inner-right-wrap">
              <div className="sa-top-heading">
              <h2>Chat</h2>
              
           </div>
          
           <div className="sa-inner-body">
               <div className="chatting-list-outer">
                 <ul>
                 {Array.isArray(chatlist.list) && chatlist.list.map((chatItem, index) => (
                    <li key={chatItem.swop_id}>
                       <div className="chatlistrow">
                          <div className="chatting-left">
                             <div className="chatimg">
                                {/* <img src="../img/chat1.png" alt=""/> */}
                                {
                                chatItem.from_item.user_id == chatItem.to_user.user_id ?
                                    <img onError={addDefaultSrc} src={chatItem.from_item.image} />
                                    : <img onError={addDefaultSrc} src={chatItem.to_item.image} />
                            }
                                <div className="chatuserimg"> <img onError={addDefaultSrc} src={API_BASE_URL + chatItem.to_user.image} alt=""/></div>
                             </div>
                             <div className="chatcontent">
                               <h2 className="chattitle"><Link to={'/chat/'+chatItem.swop_id}>{(chatItem.from_item.title) ? chatItem.from_item.title + ' - ' : ''} {chatItem.to_item.title}</Link></h2>
                                <p>{chatItem.last_message}&nbsp;</p>
                             </div>
                          </div>
                          <div className="chatting-right">
                          <div className="datetimept" dangerouslySetInnerHTML={{ __html: chatItem.last_message_time.replace('  ', '<br/>') }} />
                             <div className="chatname">{chatItem.to_user.name}</div>
                          </div>
                        
                          <div className="chatmenuspt sa-lst-clk dropdown-parents">
                             <i className="icon-20"></i>
                             <div className="dropdown-sm-menu">
                                <ul>
                                   <li>
                                      <a href="javascript:void(0);">
                                      
                                      <span>Delete Channel</span>
                                      </a>
                                   </li>
                                   <li>
                                      <a href="javascript:void(0);">
                                      
                                      <span>Block User</span>
                                      </a>
                                   </li>
                                   <li>
                                      <a href="#"> 
                                      <span>Report User</span>
                                      </a>
                                   </li>
                                </ul>
                             </div>
                             </div>
                       </div>
                    </li>
                  ) )}
                 </ul>
               </div>
           </div>
                   
           </div>
        </div>
     </div>
  </div>
    </section>
  </main>
  );
};

export default Chat;
