import React, { useEffect } from "react";
import logo from "../../img/logo-splash.png";
import DownloadSection from "./DownloadSection";
import { getSettings } from "../../slices/homeSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import facebook from "../../img/fb.png";
import insta from "../../img/insta.png";
import twitter from "../../img/tw.png";

const Footer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getSettings());
  }, []);

  const viewPage = (e, url) => {
    e.preventDefault();
    if (url === '#') {
      // Scroll to the top of the page without reloading
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      // Handle navigation for other URLs
      navigate(url);
    }
  };

  const socialLinks = useSelector((state) => state.home.settings);

  const menuData = [
    {
      title: "Company",
      items: [
        {
          label: "About us",
          url: "/content/AboutUs",
        },
        {
          label: "Faq",
          url: "/faq",
        },
        {
          label: "Contact us",
          url: "/contact-us",
        },

      ],
    },
    {
      title: "LegalPoints",
      items: [
        {
          label: "Privacy Policy",
          url: "/content/PrivacyPolicy",
        },
        {
          label: "Terms & Conditions",
          url: "/content/TermsAndConditions",
        },
        {
          label: "Cookies",
          url: "/content/Cookies",
        },
      ],
    },

  ];
  let copyright = String.fromCodePoint(0x00a9);
  let year = new Date().getFullYear();
  return (
    <footer className="sa-footer-outer sa-home-footer">
      <DownloadSection />

      <div className="bottom-footer">
        <div className="container max-container">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-12 col-md-3">
                  <div className="footer-logo">
                    <img src={logo} alt="logo" />
                    <span>
                      {copyright} {year} All Right Reserved
                    </span>
                  </div>
                </div>
                {menuData.map((menu, index) => (
                  <div key={index} className="col-6 col-md-3">
                    <div className="menu-list">
                      <h3>{menu.title}</h3>
                      <ul>
                        {menu.items.map((item, index) => (
                          <li key={index}>
                            <a href={item.url} onClick={(e) =>
                              viewPage(
                                e,
                                item.url
                              )
                            }>{item.label}</a>
                          </li>
                        ))}
                      </ul>
                      {menu.legalPoints && (
                        <div className="legal-point">
                          <h3>Legal</h3>
                          <ul>
                            {menu.legalPoints.map((point, index) => (
                              <li key={index}>
                                <Link to={point.url}>{point.label}</Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="socila-icon">
                <ul>
                  {socialLinks.fb_url && (
                    <li className="fb-icon">
                      <a href={socialLinks.fb_url} target="_blank">
                        <i>
                          <img src={facebook} alt="" />
                        </i>
                      </a>
                    </li>
                  )}
                  {socialLinks.insta_url && (
                    <li className="insta-icon">
                      <a href={socialLinks.insta_url} target="_blank">
                        <img src={insta} alt="icon" />
                      </a>
                    </li>
                  )}

                  {socialLinks.twitter_url && (
                    <li className="twite-icon">
                      <a href={socialLinks.twitter_url} target="_blank">
                        <i>
                          <img src={twitter} alt="" />
                        </i>
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="poweredby-bar">
        <p>
          Website Powered & Maintance By :{" "}
          <i>
            <img src="../../img/powerdbtlogo.png" alt="logo" />
          </i>
        </p>


      </div>
    </footer>
  );
};

export default Footer;
