import React from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../utils/config";
import { useLocation, useParams } from 'react-router-dom';

function Card({ title, image, kmcontent,price, slug,cardtype, listingType,kms_driven,fuel_type,transmission_type, banner, url, type,type_product }) {
  const navigate = useNavigate();
 

  const handleCardClick = (productSlug) => {
    // console.log('eeeeeeeeeeeeee22222',cardtype)
    if (cardtype === 'automobile') {
      navigate("/detail/" + productSlug + '/automobile');
      
    } else {
      navigate("/detail/" + productSlug + '/product');
    }
  };
  
  const handleScrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  if (listingType === "google_ads") {
    return (
      <div className="item" onClick={handleScrollUp}>
        <div className="ad-box" style={{ cursor: "pointer" }}>
          {/* <button type="button" className="btn favourite-icon">
            <i>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.62 20.8101C12.28 20.9301 11.72 20.9301 11.38 20.8101C8.48 19.8201 2 15.6901 2 8.6901C2 5.6001 4.49 3.1001 7.56 3.1001C9.38 3.1001 10.99 3.9801 12 5.3401C13.01 3.9801 14.63 3.1001 16.44 3.1001C19.51 3.1001 22 5.6001 22 8.6901C22 15.6901 15.52 19.8201 12.62 20.8101Z"
                  stroke="#ffffff"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </i>
          </button> */}
          <a href={`${url}`}>
            <div className="ad-image">
              <img src={`${API_BASE_URL}${banner}`} alt={title} />
            </div>
            {/* <div className="ad-content">
              <div className="ad-title">{title}</div>
              <div className="ad-location">
                <i className="location icon-07"></i> {kmcontent}{" "}
                <i className="chat icon-04"></i>
              </div>
            </div> */}
          </a>
        </div>
      </div>
    );
  } else {
    return (
      <div className="item" onClick={handleScrollUp}>
        <div
          className="ad-box"
          onClick={() => handleCardClick(slug)}
          style={{ cursor: "pointer" }}
        >
          {/* <button type="button" className="btn favourite-icon">
            <i>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.62 20.8101C12.28 20.9301 11.72 20.9301 11.38 20.8101C8.48 19.8201 2 15.6901 2 8.6901C2 5.6001 4.49 3.1001 7.56 3.1001C9.38 3.1001 10.99 3.9801 12 5.3401C13.01 3.9801 14.63 3.1001 16.44 3.1001C19.51 3.1001 22 5.6001 22 8.6901C22 15.6901 15.52 19.8201 12.62 20.8101Z"
                  stroke="#ffffff"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </i>
          </button> */}
          {/* <a href=""> */}
          <div className="ad-image">
          {cardtype === 'automobile' ? (
    <>
        <img src={API_BASE_URL + image} alt={title} />
        {/* <div className={type === 'Import' ? 'type-label' : 'localtype-label'}>
            {type}
        </div> */}
        {type_product !== null && type_product !== '' && (
            <div className={
              type_product === 'FEATURED' ? 'featuredside' :
              type_product === 'PREMIUM' ? 'premiumside' :
              type_product === 'CAR OF THE DAY' ? 'cardayside' :
              ''
            }>
                {type_product}
            </div>
        )}
    </>
) : (
    <img src={image} alt={title} />
)}

            
          </div>
          <div className="ad-content">
            <div className="ad-title">{title}</div>
            {cardtype == 'automobile' ? 
  <div className="ad-location">
   <div className="locoutermain d-flex">
    <span className="locitems"> {kms_driven}{" kms       "}</span>  
    <span className="locitems"> {fuel_type}{"       "}</span>   
    <span className="locitems">{transmission_type}</span>
   </div>
  </div>
: ''}

           {price ?<div className="pricelarge">
            
            {price}{" "} 
           {/* <i className="chat icon-04"></i> */}
         </div>:''}
           <div className="ad-location adlocation-bottom">
            
              <i className="location icon-07"></i> {kmcontent}{" "} 
              {/* <i className="chat icon-04"></i> */}
            </div>
        
          </div>
          {/* </a> */}
        </div>
      </div>
    );
  }
}

export default Card;
