import React, { useState, useEffect } from "react";
import Profilemenu from "./Profilemenu";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, updateProfile } from "../../slices/profileSlice";
import { API_BASE_URL } from "../../utils/config";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Swal from "sweetalert2";

import $ from "jquery";
import Loading from "../include/Loading";

const UpdateProfile = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  const profileInfo = useSelector((state) => state.profile.profileInfo);

  const [formData, setFormData] = useState({
    image: "",
    imageUrl: "",
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    bio: "",
    facebook: "",
    instagram: "",
    linkedin: "",
    mobilePrefixCode: "",
   
  });

  const handleImageUpload = (e) => {
    e.preventDefault();
    // Handle image upload here
    let file = URL.createObjectURL(e.target.files[0]);
    setFormData((prevState) => ({
      ...prevState,
      image: e.target.files[0],
      imageUrl: file,
    }));
    //  ({
    //   ...prevState,
    //   image: file,
    // }));
  };

  useEffect(() => {
    if (profileInfo) {
      // Populate form data with profileInfo data
      setFormData((prevState) => ({
        ...prevState,
        imageUrl: profileInfo.thumbimage
          ? `${API_BASE_URL}${profileInfo.thumbimage}`
          : "../img/dp.jpg",
        firstName: profileInfo.first_name || "",
        lastName: profileInfo.last_name || "",
        email: profileInfo.email || "",
        mobile: profileInfo.mobileno || "",
        bio: profileInfo.about_me || "",
        facebook: profileInfo.facebook || "",
        instagram: profileInfo.instagram || "",
        linkedin: profileInfo.linkedin || "",
        mobilePrefixCode: profileInfo.mobile_prefix_code || "",
      }));
    }
  }, [profileInfo]);

  const openUpdateMob = () => {
    $("#UpdateMob").addClass("open-it");
  };

  const handleUpdateProfile = () => {
    // Validate form fields
    if (!formData.firstName) {
      Swal.fire({
        title: "Alert",
        text: "Please enter First name.",
        icon: "error",
      });
      return;
    }
    if (!formData.lastName) {
      Swal.fire({
        title: "Alert",
        text: "Please enter last name..",
        icon: "error",
      });
      return;
    }
    if (!formData.email) {
      Swal.fire({
        title: "Alert",
        text: "Please enter Email.",
        icon: "error",
      });
      return;
    }

    // Perform image upload here (if needed)

    // Prepare data for API request
    const requestData = {
      image: formData.image,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      mobile: formData.mobile,
      bio: formData.bio,
      facebook: formData.facebook,
      instagram: formData.instagram,
      linkedin: formData.linkedin,
      mobilePrefixCode: formData.mobilePrefixCode,
    };
    dispatch(updateProfile(requestData));

    // Send API request with requestData
    // Example: dispatch(updateProfile(requestData));
  };

  const updateProfileData = useSelector(
    (state) => state.profile.updateProfileData
  );

  const isLoading = useSelector((state) => state.profile.isLoading);

  useEffect(() => {
    if (Object.keys(updateProfileData).length) {
      console.log("====> here");
      if (updateProfileData && updateProfileData.access_token) {
        localStorage.setItem(
          "signInResponse",
          JSON.stringify(updateProfileData)
        );
        console.log(
          "🚀 ~ file: Updateprofile.js:144 ~ useEffect ~ updateProfileData:",
          updateProfileData
        );

        window.location.reload();
      }
    }
  }, [updateProfileData]);

  return (
    <main className="sa-main-wrap sa-inner-pages-wrap">
      <Loading loading={isLoading} />

      <section className="sa-profile-wrap">
        <div className="container">
          <div className="row">
            <Profilemenu profileInfo={profileInfo} />
            <div className="col-lg-9">
              <div className="sa-inner-right-wrap">
                <div className="sa-top-heading">
                  <h2>Update Profile</h2>
                </div>
                <div className="sa-inner-body">
                  <div className="dp-img">
                    <img src={formData.imageUrl} alt="original" />

                    {/* <!-- <div className="img-icon">
                           <img src="img/camera.png" alt="pen" />
                           </div> --> */}
                    <div className="img-icon">
                      <input
                        type="file"
                        onChange={handleImageUpload}
                        accept="image/*"
                      />
                      <img src="../img/camera.png" alt="pen" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter First Name"
                          value={formData.firstName}
                          onChange={(e) =>
                            setFormData((prevState) => ({
                              ...prevState,
                              firstName: e.target.value,
                            }))
                          }
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Last Name"
                          value={formData.lastName}
                          onChange={(e) =>
                            setFormData((prevState) => ({
                              ...prevState,
                              lastName: e.target.value,
                            }))
                          }
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Email Address</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter Email Address"
                          value={formData.email}
                          onChange={(e) =>
                            setFormData((prevState) => ({
                              ...prevState,
                              email: e.target.value,
                            }))
                          }
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Mobile no.</label>
                        <PhoneInput
                          country={"za"}
                          value={`${formData.mobilePrefixCode}${formData.mobile}`}
                          onClick={() => openUpdateMob()}
                          onChange={(value, data) => {
                            setFormData((prevState) => ({
                              ...prevState,
                              mobile: value.slice(data.dialCode.length),
                              mobilePrefixCode: data.dialCode,
                            }));
                          }}
                          placeholder="+27"
                          inputClass={"form-control"}
                          inputStyle={{ padding: "0px 50px" }}
                        />
                        {/* <div className="mobile-input d-flex">
                          <div className="flag-box">
                            {" "}
                            <img
                              className="flagpt"
                              src="../img/flag.png"
                              alt=""
                            />{" "}
                            <i className="icon-01 arrownext"></i>{" "}
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Your Mobile no."
                            value={formData.mobile}
                            onChange={(e) =>
                              setFormData((prevState) => ({
                                ...prevState,
                                mobile: e.target.value,
                              }))
                            }
                          />
                        </div> */}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>Bio</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Add Bio"
                          value={formData.bio}
                          onChange={(e) =>
                            setFormData((prevState) => ({
                              ...prevState,
                              bio: e.target.value,
                            }))
                          }
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>
                          Facebook
                          <span className="infowrap">
                            <i className="incoico">
                              <img src="../img/info.svg" alt="" />
                            </i>
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={formData.facebook}
                          onChange={(e) =>
                            setFormData((prevState) => ({
                              ...prevState,
                              facebook: e.target.value,
                            }))
                          }
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>
                          Instagram{" "}
                          <span className="infowrap">
                            <i className="incoico">
                              <img src="../img/info.svg" alt="" />
                            </i>
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={formData.instagram}
                          onChange={(e) =>
                            setFormData((prevState) => ({
                              ...prevState,
                              instagram: e.target.value,
                            }))
                          }
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>
                          LinkedIn{" "}
                          <span className="infowrap">
                            <i className="incoico">
                              <img src="../img/info.svg" alt="" />
                            </i>
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={formData.linkedin}
                          onChange={(e) =>
                            setFormData((prevState) => ({
                              ...prevState,
                              linkedin: e.target.value,
                            }))
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sa-button-bar">
                  {/* <a href="#" className="btn btn-border">
                    Update your location
                  </a> */}
                  <button
                    className="btn btn-primary"
                    onClick={handleUpdateProfile}
                  >
                    Update Profile
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default UpdateProfile;
