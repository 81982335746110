import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../utils/config";
import { getSignInResponse } from "../utils/authUtil";

export const getSubCategories = createAsyncThunk(
  "addProduct/getSubCategories",
  async (rootCategoryId) => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
      },
    };
    if(userData.type == 'User'){
      var isbusiness = 0;
    } else if(userData.type == 'Business'){
      var isbusiness = 1;
    } else {
      var isbusiness = 1;
    }
    let body = { category_id: rootCategoryId, only_one_level: 1,isbusiness:isbusiness };

    const { data } = await axios.post(
      API_URL + `web-user/sub-categories`,
      body,
      config
    );

    return data;
  }
);

export const getSubChildCategories = createAsyncThunk(
  "addProduct/getSubChildCategories",
  async (rootCategoryId) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
      },
    };
    let body = { category_id: rootCategoryId, only_one_level: 1 };

    const { data } = await axios.post(
      API_URL + `web-user/sub-categories`,
      body,
      config
    );

    return data;
  }
);

export const getParentCategories = createAsyncThunk(
  "addProduct/getParentCategories",
  async () => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
      },
    };
    let body = { is_product_count: 1 };

    const { data } = await axios.post(
      API_URL + `web-user/getparentcategories`,
      body,
      config
    );

    return data;
  }
);

export const postProduct = createAsyncThunk(
  "addProduct/postProduct",
  async (postData) => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        // "Latitude": password.latitude,
        // "Longitude": password.longitude,
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    let body = {
      city_id: 1,
      root_category_id: postData.rootCategoryId,
      category_id: postData.categoryId,
      subcategory_id: postData.subCategoryId,
      swop_by_cash: postData.swopByCash,
      desired_swops: postData.desiredSwops,
      offer_type: postData.offerType,

      ...postData,
    };
    const { data } = await axios.post(
      API_URL +
        `user/post-product
    `,
      body,
      config
    );
    return data;
  }
);

export const editpostProduct = createAsyncThunk(
  "addProduct/editpostProduct",
  async (postData) => {
    let userData = getSignInResponse();
    console.log('postData4444',postData);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        // "Latitude": password.latitude,
        // "Longitude": password.longitude,
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    let body = {
      city_id: 1,
      root_category_id: postData.rootCategoryId,
      category_id: postData.categoryId,
      subcategory_id: postData.subCategoryId,
      swop_by_cash: postData.swopByCash,
      desired_swops: postData.desiredSwops,
      offer_type: postData.offerType,

      ...postData,
    };
    const { data } = await axios.post(
      API_URL +
        `user/edit-product
    `,
      body,
      config
    );
    return data;
  }
);

// !
// root_category_id: 1
// category_id: 22
// subcategory_id: 73
// title: Car test
// description: description
// remarks: remark
// swop_by_cash: 0
// desired_swops: jeep
// city_id: 1
// city_name: Cape Town
// lat: 30.206
// lng: 74.9302
// location_address: Cape Town
// offer_type: 1
// price: null
// image_paths[0]: https://swop.s3.us-east-2.amazonaws.com/1693906086_grahame-jenkins-p7tai9P7H-s-unsplash-min.jpg

// export const uploadImage = createAsyncThunk(
//   "addProduct/uploadImage",
//   async (image) => {
//     const config = {
//       headers: {
//         "Content-Type": "multipart/form-data",
//         "x_rest_username": "admin@restuser",
//         "x_rest_password": "admin@Access",
//         "x_rest_version": "v1",
//       },
//     };
//     //   let body = { category_id: 1, only_one_level: 1 };

//     const { data } = await axios.post(
//       API_URL + `web-user/sub-categories`,
//       image,
//       config
//     );

//     return data;
//   }
// );

// https://swopanything.girnarsoft.co.in/apis/user/product-status-action
export const postProductAction = createAsyncThunk(
  "addProduct/postProductAction",
  async (postData) => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        // "Latitude": password.latitude,
        // "Longitude": password.longitude,
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };

    const { data } = await axios.post(
      API_URL +
        `user/product-status-action
    `,
      postData,
      config
    );
    return data;
  }
);

const addProductSlice = createSlice({
  name: "addProduct",
  initialState: {
    isLoading: false,
    isError: false,
    subCategories: [],
    subChildCategories: [],
    categories: [],
    postedData: [],
    productAction: [],
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getParentCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getParentCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.categories = action.payload.data;
      state.isError = false;
    });
    builder.addCase(getParentCategories.rejected, (state) => {
      state.isLoading = false;
      state.categories = [];
      state.isError = true;
    });
    builder.addCase(getSubCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSubCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.subCategories = action.payload.data.list;
      state.isError = false;
    });
    builder.addCase(getSubCategories.rejected, (state) => {
      state.isLoading = false;
      state.subCategories = [];
      state.isError = true;
    });
    builder.addCase(getSubChildCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSubChildCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.subChildCategories = action.payload.data.list;
      state.isError = false;
    });
    builder.addCase(getSubChildCategories.rejected, (state) => {
      state.isLoading = false;
      state.subChildCategories = [];
      state.isError = true;
    });
    // builder.addCase(uploadImage.pending, (state) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(uploadImage.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.imageUploader = [...imageUploader, action.payload.data];
    //   state.isError = false;
    // });
    // builder.addCase(uploadImage.rejected, (state) => {
    //   state.isLoading = false;
    //   state.imageUploader = [];
    //   state.isError = true;
    // });
    builder.addCase(postProduct.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postProduct.fulfilled, (state, action) => {
      state.isLoading = false;
      state.postedData = action.payload.data;
      state.isError = false;
    });
    builder.addCase(postProduct.rejected, (state) => {
      state.isLoading = false;
      state.postedData = [];
      state.isError = true;
    });
    builder.addCase(editpostProduct.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editpostProduct.fulfilled, (state, action) => {
      state.isLoading = false;
      state.postedData = action.payload.data;
      state.isError = false;
    });
    builder.addCase(editpostProduct.rejected, (state) => {
      state.isLoading = false;
      state.postedData = [];
      state.isError = true;
    });
    builder.addCase(postProductAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postProductAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.productAction = action.payload.data;
      state.isError = false;
    });
    builder.addCase(postProductAction.rejected, (state) => {
      state.isLoading = false;
      state.productAction = [];
      state.isError = true;
    });
  },
});

export default addProductSlice.reducer;
