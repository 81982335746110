import React, { useState, useEffect } from "react";
import Cards from "../cards/Cards";
import Select from "react-select";
import "./List.css";
import { useDispatch, useSelector } from "react-redux";
import { getUserVehicalsList } from "../../slices/listSlice";
import ReactPaginate from "react-paginate";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import MapWithInfo from "./MapWithInfo";
import Loading from "../include/Loading";
import { useParams } from "react-router-dom";

const initialPageItems = [];
const initialDistance = 0;
const initialValue = { min: 0, max: 10 };
const initialSortval = 'FEATURED';
const initialSelltype = 0;

const UserList = () => {
  const {  userID } = useParams();
  // console.log("🚀 ~ file: List.js:16 ~ List ~ slug:", slug);

  const dispatch = useDispatch();
  // const [pageItems, setPageItems] = useState([]);
  // const [distance, setDistance] = useState({ min: 1, max: 100 });
  // const [value, setValue] = useState({ min: 0, max: 10 });
  // const [sortval, setSortval] = useState('FEATURED');
  // const [selltype, setSelltype] = useState(0);

  const [pageItems, setPageItems] = useState(initialPageItems);
  const [distance, setDistance] = useState(initialDistance);
  const [value, setValue] = useState(initialValue);
  const [sortval, setSortval] = useState(initialSortval);
  const [selltype, setSelltype] = useState(initialSelltype);
  const [selectedsortval, setSelectedsortval] = useState(null);
  const [selectedselltype, setSelectedselltype] = useState(null);
  const [perpage, setperpage] = useState(null);
  const [totalproduct, settotalproduct] = useState(null);
  const [price, setPrice] = useState({ min: 0, max: 0 }); // Initial price range
  const [tempPriceRange, setTempPriceRange] = useState({ min: 0, max: 0 }); // Initial temporary price range
  const [selectedPriceRange, setSelectedPriceRange] = useState({ min: -1, max: -1 }); // Selected price range

  const globalLocation = useSelector((state) => state.location.globalLocation);

  const resetStates = () => {
    setPageItems(initialPageItems);
    setDistance(initialDistance);
    setValue(initialValue);
    setSortval(initialSortval);
    setSelltype(initialSelltype);
    setSelectedsortval(null);
    setSelectedselltype(null);

    setTempPriceRange({ min: 0, max: 0 });
    setSelectedPriceRange({ min: -1, max: -1 });
  };

  const handleChange = (newValue) => {

    setDistance(newValue);
  };



  const handlePriceChange = (value) => {
    setTempPriceRange(value);

  };

  const handleChangeComplete = (newValue) => {
    // This function is called only after the user stops dragging
    // console.log('ssssssssss',newValue); 
    dispatch(
      getUserVehicalsList({
        currentPage: 1,
        location: globalLocation,
        userid :userID,
        sortBy: sortval,
        distance: newValue,
        offer_type: selltype,
        min_price: selectedPriceRange.min, // Assigning min value to min_price
        max_price: selectedPriceRange.max, // Assigning max value to max_price
      })
    );

  };

  const handleChangePrice = (newValue) => {
    const { min, max } = newValue; // Extracting min and max values from newValue

    setSelectedPriceRange(newValue);
    dispatch(
      getUserVehicalsList({
        currentPage: 1,
        location: globalLocation,
        userid :userID,
        sortBy: sortval,
        distance: distance,
        offer_type: selltype,
        min_price: min, // Assigning min value to min_price
        max_price: max, // Assigning max value to max_price
       
      })
    );
  };


  const resetFilter = (e) => {
    e.preventDefault();
    dispatch(
      getUserVehicalsList({
        currentPage: 1,
        userid :userID,
        location: globalLocation,
       sortval,
        distance: 0,
       
      })
    );

    resetStates();
  };



  useEffect(() => {
    // console.log("🚀 ~ file: ProductDetail.js:8 ~ ProductDetail ~ slug:", slug);
    dispatch(
      getUserVehicalsList({
        currentPage: 1,
        location: globalLocation,
        userid :userID,
        sortval,
        distance: 0,
        
      })
    );// Dispatch your API call action here using slug
    resetStates();

  }, [dispatch, userID]);

  const productsList = useSelector((state) => state.list.userproductsList);



  useEffect(() => {
    if (
      // productsList &&
      // productsList.products &&
      // productsList.products.list.length > 0
      productsList &&
      productsList.products &&
      productsList.products.list
    ) {

      setPageItems(productsList.products.list);
      settotalproduct(productsList.products.totalProducts);
      setperpage(productsList.products.perPage);


    }

  }, [productsList]);


  useEffect(() => {
    if (
      productsList &&
      productsList.products &&
      productsList.products.list
    ) {


      if (price.min === 0 && price.max === 0) {
        console.log("Setting price from productsList");
        console.log("productsList.price_range.min:", productsList.price_range.min);
        console.log("productsList.price_range.max:", productsList.price_range.max);
        setPrice({
          min: Number(productsList.price_range.min),
          max: Number(productsList.price_range.max)
        });
      } else {
        console.log("Keeping existing price:", price.min, price.max);
      }

      if (selectedPriceRange.min === -1) {
        console.log("Setting tempPriceRange from price");
        setTempPriceRange({ min: Number(price.min), max: Number(price.max) });
      } else {
        console.log("Setting tempPriceRange from selectedPriceRange");
        setTempPriceRange(selectedPriceRange);
      }
    }
  }, [productsList, price, selectedPriceRange]);


  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };


  const UserReviewoptions = [{ value: 1, label: "Swop" }, { value: 2, label: "Buy" }, { value: 3, label: "Both" }];

  const handlePageClick = (data) => {
    let currentPage = data.selected + 1;
  
    dispatch(
      getUserVehicalsList({
        currentPage: currentPage,
        location: globalLocation,
        userid :userID,
      
      })
    );
  };

  const [markers, setMarker] = useState([]);

  useEffect(() => {
    setMarker(
      pageItems.map((item) => ({
        lat: Number(item.lat),
        lng: Number(item.lng),
        title: item.title,
      }))
    );
  }, [pageItems]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isLoading = useSelector((state) => state.list.isLoading);

  const sort_by_options = productsList && productsList.sort_by_options;

  const formatLabel = (value) => `${value} KM`;
  const transformedArray =
    sort_by_options &&
    Object.keys(sort_by_options).map((key) => ({
      value: key,
      label: sort_by_options[key],
    }));

  const [selectedSortOption, setSelectedSortOption] = useState(null);


  const handleSelectChange = (selectedOption) => {
    // setSelectedSortOption(selectedOption);
    setSortval(selectedOption.value);
    setSelectedsortval(selectedOption);
    dispatch(
      getUserVehicalsList({
        currentPage: 1,
        location: globalLocation,
        sortBy: selectedOption.value,
        distance: distance,
        userid :userID,
        offer_type: selltype,
        min_price: selectedPriceRange.min, // Assigning min value to min_price
        max_price: selectedPriceRange.max, // Assigning max value to max_price
        
      })
    );

  };

  const handleSelectsell = (selectedOption) => {
    // setSelectedSortOption(selectedOption);
    setSelltype(selectedOption.value);
    setSelectedselltype(selectedOption);
    dispatch(
      getUserVehicalsList({
        currentPage: 1,
        location: globalLocation,
        sortBy: sortval,
        userid :userID,
        distance: distance,
        min_price: selectedPriceRange.min, // Assigning min value to min_price
        max_price: selectedPriceRange.max, // Assigning max value to max_price
        offer_type: selectedOption.value,
        
      })
    );

  };

  return (
    <>
      <main className="sa-main-wrap">
        <Loading loading={isLoading} />
        <div className="bgtwosection">
          <div className="container">
            <section className="sa-home-banner listbanner">
              <div className="sasecouter">
                <div className="sa-banner-outer">
                  <div className="sa-home-slider">
                    <div className="home-item">
                      <a href="">
                        <img src="../../img/list-banner1.jpg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>
                {/* {filter_type === "byCategory" ? ( */}
                <div className="cardsection sa-secure-wrap">
                  <div className="filterbywrap">
                    <h3>Filter by Distance:</h3>
                    <ul className="listing-filter-ul">

                      <li>
                        <div className="form-group">
                          <style>
                            {`
                      .input-range .input-range__slider[aria-valuenow="1"] {
                      pointer-events: none;
                      }
                      `}
                          </style>
                          <InputRange
                            formatLabel={(value) => `${value} KM`}
                            maxValue={100}
                            // minValue={1}
                            value={distance}
                            onChange={handleChange}
                            onChangeComplete={handleChangeComplete}
                            draggableTrack={false} // Set to false to disable dragging the track
                          />
                        </div>
                      {/* </li>
                      {price.min &&
                        price.max &&
                        price.min > 0 &&
                        price.max > 0 &&
                        price.min !== price.max && (
                          <><h3> Filter by Price:</h3>
                            <li>
                              <div className="form-group">
                                <InputRange
                                  maxValue={Number(price.max)}
                                  minValue={Number(price.min)}
                                  // formatLabel={formatPriceLabel}
                                  formatLabel={(value) => `${value} KM`}
                                  value={tempPriceRange}
                                  onChange={handlePriceChange}
                                  onChangeComplete={handleChangePrice}
                                />
                              </div>

                            </li> </>)}
                      <li> */}
                        
                      </li>

                      <li>
                      <div className="form-group">
                          <Select
                            options={UserReviewoptions}
                            onChange={handleSelectsell}
                            value={selectedselltype}
                            className="filterselect"
                            label="Single select"
                            placeholder="I want to "
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused
                                  ? "#e7e7e7"
                                  : "#e7e7e7",
                                borderRadius: "1rem",
                                boxShadow: "none",
                              }),
                            }}
                          />
                        </div>
                        </li>

                            <li>

                            <div className="sa-map-radio">
                        <label className="flash-label">Map View</label>
                        <label className="switch">
                          <input
                            id="MapCheckbox"
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                          />
                          <span className="sa-slider round"></span>
                        </label>
                      </div>

                            </li>

                          <li className="resetfilter-li">
                              <div className="btn btn-border" onClick={(e) =>resetFilter(e)}>Reset all</div> 
                          </li>


                    </ul>
                    {/* <div className="sa-filter-list d-flex">
                       <div className="sa-map-radio">
                        <label className="flash-label">Sort By:</label>

                        <Select
                          options={transformedArray}
                          value={selectedsortval}
                          onChange={handleSelectChange}
                          className="filterselect"
                          label="Single select"
                          placeholder="Sort"
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor: state.isFocused
                                ? "#e7e7e7"
                                : "#e7e7e7",
                              borderRadius: "1rem",
                              boxShadow: "none",
                            }),
                          }}
                        />

                      </div> 

                     
                    </div> */}


                  </div>
                  {/* <div className="filterbywrap resetallbtns">
                    <div className="small-header" onClick={(e) => resetFilter(e)}>
                      Reset all
                    </div>

                  </div> */}
                </div>
                {/* ) : null} */}
              </div>
            </section>
          </div>
        </div>
        <div className="sa-search-list-outer">
          <div className="container">
            <section className="sa-comman-section">
              <div className="sa-heading-section d-flex-wrap">

              <div className="d-flex d-flex-wrap">
                <h2 className="sa-title">
               User Listings
                </h2>

                <div className="subdescription">
                  {productsList &&
                    productsList.products &&
                    productsList.products.totalProducts}{" "}
                  result found
                </div>

                </div>

                <div className="form-group ml-auto sortprice-wrap">
                        <Select
                          options={transformedArray}
                          value={selectedsortval}
                          onChange={handleSelectChange}
                          className="filterselect"
                          label="Single select"
                          placeholder="Sort By"
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor: state.isFocused
                                ? "#e7e7e7"
                                : "#e7e7e7",
                              borderRadius: "1rem",
                              boxShadow: "none",
                            }),
                          }}
                        />
                        </div>

              </div>
              <div className="sa-fpc-wrap">
                <div
                  id="swopcafetown"
                  className="sa-full-list-outer"
                  style={{ display: isChecked ? "none" : "flex" }}
                >
                  <div className="default-carousel-simple d-flex-wrap ">
                    {pageItems &&
                      pageItems.map((card, index) => (
                        <React.Fragment key={index}>
                          <Cards
                            image={card.image}
                            title={card.title}
                            kmcontent={card.distance_text}
                            slug={card.slug}
                            listingType={card.listing_type}
                            banner={card.banner}
                            url={card.url}
                          />
                        </React.Fragment>
                      ))}
                  </div>
                </div>
                <div
                  id="swopcafetown2"
                  className="sa-full-list-outer sa-map-list-outer"
                  style={{ display: isChecked ? "flex" : "none" }}
                >
                  <div className="default-carousel-simple d-flex-wrap ">
                    {pageItems &&
                      pageItems.map((card, index) => (
                        <React.Fragment key={index}>
                          <Cards
                            image={card.image}
                            title={card.title}
                            kmcontent={card.distance_text}
                            slug={card.slug}
                          />
                        </React.Fragment>
                      ))}
                  </div>
                  <div className="sa-map-part">
                    <MapWithInfo markers={markers} />
                    {/* <img src="img/gmap.jpg" alt="map" /> */}
                  </div>
                </div>
              </div>
            </section>
            {perpage < totalproduct ?
              <ReactPaginate
                previousLabel={<i className="icon-02"></i>}
                nextLabel={<i className="icon-12"></i>}
                breakLabel={"..."}
                pageCount={
                  productsList &&
                  productsList.products &&
                  productsList.products.totalPages
                }
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"sa-pagination"}
                // pageClassName={"page-item"}
                // pageLinkClassName={"page-link"}
                // previousClassName={"icon-02"}
                // previousLinkClassName={"page-link"}
                // nextClassName={"page-item"}
                // nextLinkClassName={"page-link"}
                // breakClassName={"page-item"}
                // breakLinkClassName={"page-link"}
                // activeClassName={"active-page"}
                activeLinkClassName={"active"}
              /> : ''}
            {/* ReactPaginate */}

            {/* <div className="sa-pagination">
              <a href="">
                <i className="icon-02"></i>
              </a>
              <a href="" className="active">
                1
              </a>
              <a href="">2</a>
              <a href="">3</a>
              <a href="">. . .</a>
              <a href="">10</a>
              <a href="">
                <i className="icon-12"></i>
              </a>
            </div> */}
          </div>
        </div>
      </main>
    </>
  );
};

export default UserList;
