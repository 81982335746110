import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes,useParams } from "react-router-dom";
import Home from "./components/home/Home";
import Footer from "./components/include/Footer";
import Header from "./components/include/Header";
import List from "./components/products/List";
import Terms from "./components/pages/Terms";
import Privacy from "./components/pages/Privacy";
import About from "./components/pages/About";
import Content from "./components/pages/Content";
import Faq from "./components/pages/Faq";
import Cookies from "./components/pages/Cookies";
import ContactUs from "./components/pages/ContactUs";
import Nofound from "./components/pages/Nofound";
import AutomobileList from "./components/products/Automobile";
import UserList from "./components/products/UserList";
import "./App.css";
import "./css/bootstrap.min.css";
import "./css/normalize.css";
import "./css/slick.css";
import "./css/slick-theme.css";
import "./css/style.css";
import "./css/custom.css";
import ProductDetail from "./components/products/ProductDetail";
import VehicleDetail from "./components/products/VehicleDetail";
import UpdateProfile from "./components/profile/UpdateProfile";
import UserProfile from "./components/profile/UserProfile";
import Profile from "./components/profile/Profile";
import Swop from "./components/profile/Swop";
import CompletedSwop from "./components/profile/CompletedSwop";
import Chat from "./components/profile/Chat";
import ChatConversion from "./components/profile/ChatConversion";
import Referral from "./components/profile/Referral";
import Wishlist from "./components/profile/Wishlist";

import PrivateRoutes from "./routing/PrivateRoutes";

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          {/* Private routes */}

          <Route element={<PrivateRoutes />}>
            <Route path="/update-profile" element={<UpdateProfile />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/swops" element={<Swop />} />
            <Route path="/completed-swop" element={<CompletedSwop />} />
            <Route path="/chat-history" element={<Chat />} />
            <Route path="/chat/:id" element={<ChatConversion />} />
            <Route path="/referral" element={<Referral />} />
            <Route path="/wishlist" element={<Wishlist />} />
         
          </Route>
          {/* Public routes */}
          <Route path="/" element={<Home />} exact />
          <Route
            path="/listing/:filter_type?/:category_slug?"
            element={<List />}
          />
            <Route
            path="/userlisting/:userID"
            element={<UserList />}
            />
             <Route
            path="/listing/automobile/:category_slug?"
            element={<AutomobileList />}
          />
         
          {/* <Route
            path="/listing/byCategory/:category_slug?"
            element={<List />}
          /> */}
          <Route path="/product/:slug" element={<ProductDetail />} />
          <Route path="/profile/:slug" element={<UserProfile />} />
           {/* <Route path="/detail/:slug" element={<ProductDetail />} /> */}
           <Route path="/detail/:slug/:type" element={<DetailRoutes />} />
          <Route path="/terms-of-service" element={<Terms />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/about" element={<About />} />
          <Route path="/content/:page_type" element={<Content />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/cookies" element={<Cookies />} />
          <Route path="*" element={<Nofound />} />
          {/* <Route path="/page-not-found" element={<Nofound />} /> */}

          {/* <Route path="/" element={<Home />} />
          <Route
            path="/listing/:filter_type?/:category_slug?"
            element={<List />}
          />
          <Route path="/product/:slug" element={<ProductDetail />} />
          <Route path="/detail/:slug" element={<ProductDetail />} />
          <Route path="/terms-of-service" element={<Terms />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/update-profile" element={<UpdateProfile />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/page-not-found" element={<Nofound />} />
          <Route path="/swops" element={<Swop />} />
          <Route path="/completed-swop" element={<CompletedSwop />} />
          <Route path="/chat-history" element={<Chat />} />
          <Route path="/chat/:id" element={<ChatConversion />} />
          <Route path="/referral" element={<Referral />} />
          <Route path="/wishlist" element={<Wishlist />} /> */}
        </Routes>
        
        <Footer />
      </BrowserRouter>
    </>
    
  );

}
function DetailRoutes() {
  // Parse the 'type' query parameter from the URL
  const { type } = useParams();
  

  // Render the appropriate detail component based on the 'type' query parameter
  if (type === 'automobile') {
    return <VehicleDetail />;
  } else if (type === 'product') {
    return <ProductDetail />;
  } else {
    // Handle other cases, such as invalid 'type' or no 'type' provided
    return <Nofound />;
  }
}

export default App;
