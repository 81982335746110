import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../utils/config";

export const getCategories = createAsyncThunk(
  "header/getCategories",
  async () => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
      },
    };
    let body = { is_product_count: 1 };

    const { data } = await axios.post(
      API_URL + `web-user/getcategories`,
      body,
      config
    );

    return data;
  }
);

export const getTrendingCategories = createAsyncThunk(
  "header/getTrendingCategories",
  async () => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
      },
    };
    let body = { is_product_count: 1 };

    const { data } = await axios.post(
      API_URL + `web-user/gettrendingcategories`,
      body,
      config
    );

    return data;
  }
);


export const getSeach = createAsyncThunk(
  "header/getSeach",
  async (keyword) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
      },
    };
    let body = { keyword: keyword };

    const { data } = await axios.post(
      API_URL + `web-user/search`,
      body,
      config
    );

    return data;
  }
);

// export const getDashboard = createAsyncThunk("home/getDashboard", async () => {
//   const config = {
//     headers: {
//       "Accept": "application/json",
//       "x_rest_username": "admin@restuser",
//       "x_rest_password": "admin@Access",
//       "x_rest_version": "v1",
//       "device": "web",
//       "language": "en",
//       "latitude": "26.83762010",
//       "longitude": "75.83360530",
//       "city_id": "1",
//       "city_name": "Cape Town",
//     },
//   };
//   let body;

//   const { data } = await axios.post(
//     API_URL + `web-user/dashboard`,
//     body,
//     config
//   );

//   return data;
// });

const headerSlice = createSlice({
  name: "header",
  initialState: {
    isLoading: false,
    isError: false,
    categories: [],
    trendingcategories: [],
    search:[],
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.categories = action.payload.data;
      state.isError = false;
    });
    builder.addCase(getCategories.rejected, (state) => {
      state.isLoading = false;
      state.categories = [];
      state.isError = true;
    });
    builder.addCase(getTrendingCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTrendingCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.trendingcategories = action.payload.data;
      state.isError = false;
    });
    builder.addCase(getTrendingCategories.rejected, (state) => {
      state.isLoading = false;
      state.trendingcategories = [];
      state.isError = true;
    });
    builder.addCase(getSeach.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSeach.fulfilled, (state, action) => {
      state.isLoading = false;
      state.search = action.payload.data;
      state.isError = false;
    });
    builder.addCase(getSeach.rejected, (state) => {
      state.isLoading = false;
      state.search = [];
      state.isError = true;
    });
  },
});

export default headerSlice.reducer;
